import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import { IndexFileType } from "../../types/indexes";
import { Column } from "../../types/ui";
import { formatPercentage } from "../../utils/numeric";

export const PERIOD_IN_YEARS = -20;

export const ORDERED_INDEX_FILE_TYPES: IndexFileType[] = [
  "factsheet",
  "methodology_guidebook",
  "constituents_list",
  "corporate_actions",
  "benchmark_statement",
  "custom_button"
];

export const RadioButtonItems = [
  {
    key: "YTD",
    label: "YTD",
    value: "YTD",
  },
  {
    key: "1Y",
    label: "1 Year",
    value: "-1",
  },
  {
    key: "3Y",
    label: "3 Years",
    value: "-3",
  },
  {
    key: "5Y",
    label: "5 Years",
    value: "-5",
  },
  {
    key: "max",
    label: "Max.",
    value: "max",
  },
];

export const top10ConstituentsCols = [
  {
    key: "name",
    label: "NAME",
  },
  {
    key: "symbol",
    label: "TICKER",
  },
  {
    key: "index_shares",
    label: "INDEX SHARES",
  },
];

export const indexTableCols: Column[] = [
  {
    key: "index_id",
    label: "Index Symbol",
  },
  {
    key: "currency",
    label: "Currency",
  },
  {
    key: "figi",
    label: "FIGI",
  },
  {
    key: "isin",
    label: "ISIN",
  },
  {
    key: "n_constituents",
    label: "N° of Constituents",
  },
  {
    key: "lauch_date",
    label: "Inception Date",
  },
  {
    key: "value",
    label: "Value",
    width: 140,
  },
  {
    key: "last_day_return",
    label: "Last Day Return",
    renderCell: (data) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color:
            data.valueDiff > 0
              ? "#2AA100"
              : data.valueDiff < 0
              ? "#EA4D4D"
              : "#000",
        }}
      >
        <Box>{data.last_day_return}</Box>
        <Box pl={1}>
          {data.valueDiff > 0 && <ArrowUpwardIcon />}
          {data.valueDiff < 0 && <ArrowDownwardIcon fontSize="small" />}
        </Box>
      </Box>
    ),
  },
];

const width = "16%";

export const indexReturnsColumnSettings = [
  {
    key: "YTD",
    label: "YTD",
  },
  {
    key: "1Y",
    label: "1 Year",
    shortLabel: "1 Y",
  },
  {
    key: "3Y",
    label: "3 Years",
    shortLabel: "3 Yrs",
  },
  {
    key: "5Y",
    label: "5 Years",
    shortLabel: "5 Yrs",
  },
  {
    key: "max",
    label: "Max.",
  },
];

export const indexReturnsPercentCols = indexReturnsColumnSettings.map((s) => ({
  ...s,
  renderCell: (data: any) => <div>{formatPercentage(data[s.key])}</div>,
  colSpan: 1,
  width,
}));

export const indexReturnsRawCols = indexReturnsColumnSettings.map((c) => ({
  ...c,
  renderCell: (data: any) => (
    <div>
      {typeof data[c.key] === "number"
        ? data[c.key].toFixed(2)
        : data[c.key] ?? "0"}
    </div>
  ),
  colSpan: 1,
  width,
}));
