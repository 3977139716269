import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
swiperArrowButton: {
    position: "relative",
    padding: ".5rem",
    backgroundColor: "#0d0f17",
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "3px 3px 10px #1b57fee1",
    "&:after": {
        fontSize: ".7rem",
        fontWeight: "bold",
        color: "#4174ff",
    },
},
swiperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
},
swipperArrow: {
    fontSize: "1rem !important",
    color: "#235cff",
},
right: {
    left: "20px",
    [theme.breakpoints.down('md')]: {
        left: "0px",
    },
},
left: {
    right: "20px",
    [theme.breakpoints.down('md')]: {
        right: "0px",
    },
},
swiperSlide: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    "& img": {
        width: "100%",
    },
}
}));
