import Img1 from "../../assets/images/products/thematic-data/index1.png";
import Img2 from "../../assets/images/products/thematic-data/index2.png";
import Img3 from "../../assets/images/products/thematic-data/index3.png";
import Img4 from "../../assets/images/products/thematic-data/index4.png";
import Carousel from "../Carousel";
import ThematicIndexesCarouselCard from "./Card";

const ThematicIndexesCarousel = () => {
  return (
    <Carousel slidesPerView={4} spaceBetween={0} breakpoints={{
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      1440: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
      1920: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
    }}>
      <ThematicIndexesCarouselCard
        image={Img1}
        type="Equity Index"
        title="BITA Gaming and E-Sports Giants EW Index"
        link="/index/BGAMGE"
      />
      <ThematicIndexesCarouselCard
        image={Img2}
        type="Equity Index"
        title="BITA Airlines and Airports Giants Index"
        link="/index/BNAAGI"
      />
      <ThematicIndexesCarouselCard
        image={Img3}
        type="Equity Index"
        title="BITA NFT and Blockchain Giants Index"
        link="/index/BNFTBG"
      />
      <ThematicIndexesCarouselCard
        image={Img4}
        type="Equity Index"
        title="BITA Green Energy Giants Index"
        link="/index/BNGEGI"
      />
      <ThematicIndexesCarouselCard
        image={Img1}
        type="Equity Index"
        title="BITA Gaming and E-Sports Giants EW Index"
        link="/index/BGAMGE"
      />
      <ThematicIndexesCarouselCard
        image={Img2}
        type="Equity Index"
        title="BITA Airlines and Airports Giants Index"
        link="/index/BNAAGI"
      />
      <ThematicIndexesCarouselCard
        image={Img3}
        type="Equity Index"
        title="BITA NFT and Blockchain Giants Index"
        link="/index/BNFTBG"
      />
      <ThematicIndexesCarouselCard
        image={Img4}
        type="Equity Index"
        title="BITA Green Energy Giants Index"
        link="/index/BNGEGI"
      />
    </Carousel>
  );
};

export default ThematicIndexesCarousel;
