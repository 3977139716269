import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getFamiliesIndexes,
  getIndexesWithWeight,
  getAssetClassOptions,
  getAssetIndexTypeOptions,
  getIndexesEOD,
  getIndexValues,
  getIndexValuesFirstSecond,
  getV2IndexList,
  getV2IndexItemData,
  getIndexHistory,
  getTokenPriceIndexEOD,
  getIndexLabs,
} from "../../services";
import { FamiliesIndexes } from "../../types";
import {
  AssetClassOption,
  IndexEod,
  IndexHistory,
  IndexItemData,
  IndexLab,
  IndexListItem,
  IndexTypeOption,
  IndexValue,
  IndexValueFirstSecond,
  IndexWithWeight,
} from "../../types/indexes";
import { RootState } from "../store";

export interface IndexesState {
  families: FamiliesIndexes;
  indexes: IndexWithWeight[];
  indexList: IndexListItem[];
  indexItemData: IndexItemData;
  eod: IndexEod;
  eodIndexDetail: IndexEod;
  history: IndexHistory[];
  tokenPriceEod: IndexEod;
  indexValues: IndexValue[];
  indexValuesFirstSecond: IndexValueFirstSecond[];
  assetClassOptions: AssetClassOption[];
  indexTypeOptions: IndexTypeOption[];
  indexLab: IndexLab[];
  isLoading: boolean;
  error: string;
}

const initialState: IndexesState = {
  families: {},
  indexes: [],
  indexList: [],
  indexItemData: {
    asset_class: 0,
    description: "",
    index_id: "",
    index_type: 0,
    name: "",
    ticker: "",
    web_status: null,
    files: [],
    top_10_constituents_status: "A",
    constituents_file_status: "A",
    custom_button_status: "I",
    custom_button_name: "",
    custom_button_url: "",
    file_should_be_downloadable: "I"
  },
  eod: {},
  eodIndexDetail: {},
  history: [],
  tokenPriceEod: {},
  indexValues: [],
  indexValuesFirstSecond: [],
  assetClassOptions: [],
  indexTypeOptions: [],
  indexLab: [],
  isLoading: false,
  error: "",
};

// Thunks
export const fetchFamiliesIndex = createAsyncThunk(
  "indexes/families/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getFamiliesIndexes();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexesWithWeight = createAsyncThunk(
  "indexes/indexes/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getIndexesWithWeight();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexList = createAsyncThunk(
  "indexes/indexList/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getV2IndexList();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexItemData = createAsyncThunk(
  "indexes/indexItemData/fetch",
  async (indexId: string, thunkAPI) => {
    try {
      const data = await getV2IndexItemData(indexId);
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// I had to add another state to the redux (eodIndexDetail) because a race condition was occurring when making the same request with 2 different payloads between one page and another (IndexPage and IndexDetailsPage components). See the issue https://app.clickup.com/t/4610131/IT-15753
// Maybe this could be improve it with a different approach.
// This also fix this issue: https://app.clickup.com/t/4610131/IT-14378
export const fetchIndexDetailEOD = createAsyncThunk(
  "indexes/eodIndexDetail/fetch",
  async (
    params: {
      indexes: string[];
      endDate: string;
      startDate?: string | undefined;
    },
    thunkAPI
  ) => {
    try {
      const data = await getIndexesEOD(
        params.indexes, 
        params.endDate,
        params.startDate || undefined
      );
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexesEOD = createAsyncThunk(
  "indexes/eod/fetch",
  async (
    params: {
      indexes: string[];
      endDate: string;
      startDate?: string | undefined;
    },
    thunkAPI
  ) => {
    try {
      const data = await getIndexesEOD(
        params.indexes, 
        params.endDate,
        params.startDate || undefined
      );
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexHistory = createAsyncThunk(
  "indexes/history/fetch",
  async (
    params: {
      indexId: string;
      startDate: string;
      endDate: string;
      frequency: string;
    },
    thunkAPI
  ) => {
    try {
      const data = await getIndexHistory(
        params.indexId,
        params.startDate,
        params.endDate,
        params.frequency
      );
      return { data };
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchTokenPriceIndexEOD = createAsyncThunk(
  "indexes/tokenPriceEod/fetch",
  async (
    params: {
      assets: string[];
      startDate: string;
      endDate: string;
    },
    thunkAPI
  ) => {
    try {
      const data = await getTokenPriceIndexEOD(
        params.assets,
        params.startDate,
        params.endDate
      );
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexValues = createAsyncThunk(
  "indexes/indexValues/fetch",
  async (
    params: {
      indexes: string[];
    },
    thunkAPI
  ) => {
    try {
      const data = await getIndexValues(params.indexes);
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexValuesFirstSecond = createAsyncThunk(
  "indexes/indexValuesFirstSecond/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getIndexValuesFirstSecond();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexAssetClassOptions = createAsyncThunk(
  "indexes/assetClassOptions/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getAssetClassOptions();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexTypes = createAsyncThunk(
  "indexes/indexTypeOptions/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getAssetIndexTypeOptions();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchIndexLabs = createAsyncThunk(
  "indexes/indexLab/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getIndexLabs();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const indexesSlice = createSlice({
  name: "indexes",
  initialState,
  reducers: {
    setFamilies: (state, action: PayloadAction<FamiliesIndexes>) => {
      state.families = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFamiliesIndex.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFamiliesIndex.fulfilled, (state, action) => {
      state.isLoading = false;
      state.families = action.payload?.data;
    });
    builder.addCase(fetchFamiliesIndex.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexesWithWeight.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexesWithWeight.fulfilled, (state, action) => {
      state.isLoading = false;
      state.indexes = action.payload?.data;
    });
    builder.addCase(fetchIndexesWithWeight.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.indexList = action.payload.data;
    });
    builder.addCase(fetchIndexList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexItemData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexItemData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.indexItemData = action.payload.data;
    });
    builder.addCase(fetchIndexItemData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexesEOD.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexesEOD.fulfilled, (state, action) => {
      state.isLoading = false;
      state.eod = action.payload?.data;
    });
    builder.addCase(fetchIndexesEOD.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexDetailEOD.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexDetailEOD.fulfilled, (state, action) => {
      state.isLoading = false;
      state.eodIndexDetail = action.payload?.data;
    });
    builder.addCase(fetchIndexDetailEOD.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexHistory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;

      const currentItems =
        state.history?.filter(
          ({ index_id }) => !(data.index_id === index_id)
        ) ?? [];
      state.history = [...currentItems, action.payload?.data];
    });
    builder.addCase(fetchIndexHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchTokenPriceIndexEOD.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTokenPriceIndexEOD.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tokenPriceEod = action.payload?.data;
    });
    builder.addCase(fetchTokenPriceIndexEOD.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexValues.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexValues.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      const currentIndexValues =
        state.indexValues?.filter(
          ({ index_id }) => !data.find((item) => item.index_id === index_id)
        ) ?? [];
      state.indexValues = [...currentIndexValues, ...data];
    });
    builder.addCase(fetchIndexValues.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexValuesFirstSecond.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexValuesFirstSecond.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      const currentValues =
        state.indexValuesFirstSecond?.filter(
          ({ symbol }) => !data.find((item) => item.symbol === symbol)
        ) ?? [];
      state.indexValuesFirstSecond = [...currentValues, ...data];
    });
    builder.addCase(fetchIndexValuesFirstSecond.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexAssetClassOptions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexAssetClassOptions.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.assetClassOptions = data;
      state.isLoading = false;
    });
    builder.addCase(fetchIndexAssetClassOptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexTypes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexTypes.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.indexTypeOptions = data;
      state.isLoading = false;
    });
    builder.addCase(fetchIndexTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchIndexLabs.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndexLabs.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.indexLab = data;
      state.isLoading = false;
    });
    builder.addCase(fetchIndexLabs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });
  },
});

export const { setFamilies } = indexesSlice.actions;

export const selectIndexes = (state: RootState) => state.indexes.indexes;
export const selectIndexValues = (state: RootState) =>
  state.indexes.indexValues;
export const selectFamiliesIndexes = (state: RootState) =>
  state.indexes.families;
export const selectIndexList = (state: RootState) => state.indexes.indexList;
export const selectIndexItemData = (state: RootState) => state.indexes.indexItemData;
export const selectAssetClassOptions = (state: RootState) =>
  state.indexes.assetClassOptions;
export const selectIndexTypeOptions = (state: RootState) =>
  state.indexes.indexTypeOptions;
export const selectTokenPriceIndexEOD = (state: RootState) =>
  state.indexes.tokenPriceEod;
export const selectIndexHistory = (state: RootState) => state.indexes.history;
export const selectIndexEod = (state: RootState) => state.indexes.eod;
export const selectIndexDetailEod = (state: RootState) => state.indexes.eodIndexDetail;
export const selectIndexLab = (state: RootState) => state.indexes.indexLab;
export const selectIndexLoading = (state: RootState) => state.indexes.isLoading;

export default indexesSlice.reducer;
