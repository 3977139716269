import { FC } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { News } from "../../types/news";
import Carousel from "../Carousel";
import HomeCard from "../HomeCard";

interface Props {
  data: News[];
}

const HomeCardCarousel: FC<Props> = ({ data }) => {
  return (
    <Carousel spaceBetween={10} slidesPerView={4} breakpoints={{
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      1440: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
      1920: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
    }}>
      {data.map(
        ({
          id_m_web_news,
          categorization,
          createdate,
          title,
          link_media,
          preview_image,
        }) => (
          <Box key={id_m_web_news}>
            <HomeCard
              type={categorization?.name ?? "Unknown Type"}
              title={title}
              date={moment(createdate).format("MMMM yyyy")}
              bgImage={preview_image}
              link={link_media}
            />
          </Box>
        )
      )}
    </Carousel>
  );
};

export default HomeCardCarousel;
