import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "250px",
    padding: "1.25rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      width: "240px",
    },
  },
  type: {
    display: "inline-block",
    borderBottom: "1px solid #fff",
    fontSize: 11,
    fontWeight: 500,
  },
  title: {
    minHeight: "10rem",
    maxWidth: "80%",
    display: "flex",
    alignItems: "flex-end",
    fontSize: 15,
    fontWeight: 600,
  },
  date: {
    paddingTop: "1rem",
    fontSize: 13,
  },
}));
