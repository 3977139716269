import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  backBtn: {
    paddingTop: "2.5rem",
  },
  main: {
    padding: "3rem 6rem",
  },
  innerContainer: {
    margin: "0 -9.5rem",
    [theme.breakpoints.down("xl")]: {
      margin: "0 auto",
    },
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    color: theme.palette.primary.main,
  },
  sectionHeading: {
    fontSize: 30,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  tableContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  selectorsContainer: {
    display: "flex",
    paddingBottom: theme.spacing(3),
    flexWrap: "wrap",

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
    },
  },
  selectContainer: {
    paddingRight: theme.spacing(3),
  },
  datePickersContainer: {
    marginLeft: "auto",
    display: "flex",
  },
  legalContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  withBorderBottom: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  announcementsContainer: {
    padding: "3rem 0",
  },
  mobileDatePicker: {
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(3),
    },
  },
  utcDisclaimer: {
    marginTop: "1rem", 
    fontSize: ".9rem", 
    letterSpacing: '0.1em', 
    textAlign:'right', 
    color: '#8e8e8e' 
  }
}));
