import { FC } from "react";
import { Box } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { News } from "../../types/news";
import Carousel from "../Carousel";
import CaseStudyCard from "../CaseStudyCard";

interface Props {
  data: News[];
}

const DesktopCarrousel = (data: News[]) => (
  <Box pt={3} px={20}>
    <Carousel
      spaceBetween={1}
      navigation={false}
      breakpoints={{
        1024: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        1440: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        1920: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
      }}
    >
      {data.map((item) => (
        <CaseStudyCard key={item.id_m_web_news} data={item} />
      ))}
    </Carousel>
  </Box>
);

const MobileCarrousel = (data: News[]) => (
  <Carousel
    slidesPerView={3}
    spaceBetween={10}
    breakpoints={{
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    }}
  >
    {data.map((item) => (
      <CaseStudyCard key={item.id_m_web_news} data={item} />
    ))}
  </Carousel>
);



const ClientsCaseStudies: FC<Props> = ({ data }) => {
  const inMobile = useIsMobileView();
  return (
    <Box pt={3}>
     {inMobile ? MobileCarrousel(data) : 
     DesktopCarrousel(data)}
    </Box>
  );
};

export default ClientsCaseStudies;
