import { Box } from "@mui/material";
import Img1 from "../../assets/images/products/crypto-offering/card1.png";
import Img2 from "../../assets/images/products/crypto-offering/card2.png";
import Img3 from "../../assets/images/products/crypto-offering/card3.png";
import Img4 from "../../assets/images/products/crypto-offering/card4.png";
import Carousel from "../Carousel";
import ThematicIndexesCarouselCard from "../ThematicIndexesCarousel/Card";

const CryptoOfferingCarousel = () => {
  return (
    <Box pt={3}>
      <Carousel slidesPerView={4} spaceBetween={20} breakpoints={{
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      600: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      1440: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
      1920: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
    }}>
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Smart Contract Platforms Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Smart Contract Platforms Index"
          link=""
        />
      </Carousel>
    </Box>
  );
};

export default CryptoOfferingCarousel;
