import { FC, useMemo, useRef } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import SwiperInstance from "swiper";
import { Pagination} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Column } from "../../types/ui";
import Table from "../Table";
import { useStyles } from "./styles";
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/navigation';

interface Props {
  inMobile?: boolean;
  columns: Column[];
  data: any;
}

const ResponsiveTable: FC<Props> = ({ inMobile, columns, data }) => {
  const swiperRef = useRef<SwiperInstance | null>(null);
  const classes = useStyles();

  const columnSets = useMemo(() => {
    const result: Column[][] = [];
    columns.forEach((col, index) => {
      if (index % 3 === 2 || index === columns.length - 1) {
        result.push(columns.slice(Math.floor(index / 3) * 3, index + 1));
      }
    });
    return result;
  }, [columns]);

  return (
    <Box className={classes.root} pt={4} pb={6}>
   {inMobile ? (
  <div >
  
<Swiper
  spaceBetween={0}
  onSwiper={(swiper: any) => (swiperRef.current = swiper)}
  loop={true}
  modules={[Pagination]} 
  pagination={true}
>
          {columnSets.map((columnSet) => (
            <SwiperSlide key={columnSet[0].id}>
                <Box sx={{padding: '40px 0'}}>
                <Table
                  headerHeight={60}
                  columns={columnSet}
                  data={[data]}
                  dense
                  hasPagination={false}
                  />
                </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      ) : (
        <Table columns={columns} data={[data]} dense hasPagination={false} />
      )}
    </Box>
  );
};

export default ResponsiveTable;
